<template>
    <div>
        <vz-sidebar ref="sidebar"></vz-sidebar>
        <vz-header></vz-header>

        <section v-if="!isLoggedIn" class="hero">

            <div class="container mt-lg-4 mt-2 mt-sm-2">

                <div class="row end-of-universe">

                    <div class="col-12 col-xl-6 order-xl-1 col-lg-12 order-lg-1 col-md-12 order-md-1 order-1 pt-6">
                        <h1><span class="upper">Izračunajte oportunitetno izgubo</span>Koliko vrednosti <br>izgublja vaš denar?</h1>
                        <p class="pl-6">Izračunajte, koliko kupne moči izpuhti iz vaših prihrankov na bančnem računu.</p>
                    </div>

                    <div class="col-12 col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-2 order-2 mt-md-6 mt-lg-0">
                        <picture>
                            <source media="(max-width: 480px)" srcset="/images/hero_img_mobile.png">
                            <source media="(max-width: 1199.98px)" srcset="/images/hero_horizontal.png">
                            <img src="/images/hero.png" alt="Vzajemci" class="img-fluid">
                        </picture>
                    </div>

                </div>

                <div class="row go-up">
                    <!-- Form -->
                    <div class="col-12 col-xl-7 col-lg-12 col-md-12 col-sm-12">

                        <div v-if="step === 1 && !isResetPassword">
                            <div class="form-wrapper presignin-wrapper" data-aos="fade-up" data-aos-duration="240" data-aos-easing="ease-out">
                                <form class="presignin">
                                    <div class="form-group">
                                        <label for="input1">Višina sredstev na računu</label>
                                        <money v-model="deposit" v-bind="money" id="input1" @keypress.native="checkValue"></money>
                                        <small id="input2" class="form-text text-muted">Izračun je informativne narave.</small>
                                    </div>
                                    <button type="button" class="btn btn-primary btn-lg" @click="nextStep">Nadaljujte na izračun</button>
                                </form>
                            </div>
                        </div>
                        <div v-else>
                            <div class="login">
                                <div v-if="!isLoggedIn">
                                    <div v-if="!isRegistered && !isForgottenSent">
                                        <div class="form-wrapper presignin-wrapper" data-aos="fade-up" data-aos-duration="900" data-aos-easing="linear">
                                            <FormulateForm
                                                v-if="!isResetComplete"
                                                v-model="values"
                                                name="vzajemci-form"
                                                :schema="active_form"
                                                @submit="userActions"
                                            />
                                            <p v-if="loginError" v-text="loginErrorText" class="error"></p>
                                            <div v-if="message">
                                                <p :class="isResetComplete ? '' : 'error'" v-html="message"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else-if="isForgottenSent" class="form-wrapper">
                                        <p>Na vpisani elektronski naslov smo poslali navodila za ponastavitev gesla.</p>
                                    </div>
                                    <div v-else class="form-wrapper">
                                        <p>Hvala za registracijo. Na vaš elektronski naslov <span v-text="values.email"></span> smo vam poslali povezavo za potrditev registracije.</p>
                                    </div>

                                </div>
                                <div v-else>
                                    <!--            <button v-if="isLoggedIn" @click="userLogout">Odjavi se</button>-->
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </section>
        <template v-else>
            <vz-hero></vz-hero>
            <vz-calculator ref="calculator"></vz-calculator>
            <vz-contact :user="user"></vz-contact>
        </template>

        <vz-footer></vz-footer>
    </div>
</template>

<script>
import {Money} from 'v-money';
import VueFormulate from '@braid/vue-formulate';
let sl = require('./../lang/sl.js').default;

const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

Vue.use(VueFormulate, {
    plugins: [ sl ],
    locale: 'sl'}
);

export default {
    name: 'Page',
    props: {
        uuid: {
            type: String,
            required: false
        },
        loggedin_user: {
            type: String,
            required: false
        },
        id: String
    },
    components: {
        Money
    },
    data () {
        return {
            deposit: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' €',
                precision: 0,
                masked: false
            },
            step: 1,
            //deposit: null,
            values: {},
            active_form : null,
            schema_login: [
                {
                    component: 'p',
                    children: 'Obstoječi uporabniki se prijavite',
                    id: 'p-text-1'
                },
                {
                    type: 'email',
                    name: 'email',
                    label: 'Vaš e-mail',
                    validation: 'required|email'
                },
                {
                    type: 'password',
                    name: 'password',
                    label: 'Geslo',
                    validation: 'required'
                },
                {
                    type: 'hidden',
                    name: 'action',
                    value: '1'
                },
                {
                    type: 'submit',
                    label: 'Prijava'
                },
                {
                    component: 'a',
                    href: '#',
                    id: 'p-link-1',
                    class: 'forgot-button',
                    children: 'Ste pozabili geslo?',
                },
                {
                    component: 'a',
                    href: '#',
                    id: 'p-text-2',
                    class: 'register-button',
                    children: 'Nazaj na registracijo',
                }
            ],
            schema_register: [
                {
                    component: 'a',
                    href: '#',
                    children: 'Že imate račun? Prijavite se.',
                    class: 'login-button',
                    id: 'p-link-1'
                },
                {
                    component: 'p',
                    children: 'Izračun je na voljo registriranim uporabnikom. Ustvarite si brezplačni račun in bodite deležni tudi številnih drugih ugodnosti.',
                    id: 'p-text-1'
                },
                {
                    type: 'text',
                    name: 'name',
                    validation: 'required',
                    label: 'Vaše ime in priimek',
                },
                {
                    type: 'email',
                    name: 'email',
                    label: 'Vaš e-mail',
                    help: 'Vašega e-mail naslova ne bomo z nikomer delili.',
                    validation: '^required|email'
                },
                {
                    type: 'password',
                    name: 'password',
                    label: 'Geslo',
                    validation: 'required'
                },
                {
                    type: 'checkbox',
                    name: 'consulting',
                    'label-class': 'terms',
                    'outer-class': 'consulting',
                    label: 'Želim si tudi svetovanje',
                },
                {
                    type: 'checkbox',
                    name: 'terms',
                    'label-class': 'terms',
                    'outer-class': 'consulting',
                    label: 'Strinjam se z uporabo posredovanih podatkov v marketinške namene, ki so skladni s politiko zasebnosti.'
                },
                {
                    type: 'checkbox',
                    name: 'privacy',
                    'label-class': 'terms',
                    label: 'Strinjam se s pogoji poslovanja.',
                    validation: 'required',
                    value: '1',
                    checked: true,
                },
                {
                    type: 'hidden',
                    name: 'register',
                    value: '1'
                },
                {
                    type: 'submit',
                    label: 'Nadaljujte'
                }
            ],
            schema_forgot: [
                {
                    type: 'email',
                    name: 'email',
                    label: 'Vaš e-mail',
                    validation: '^required|email'
                },
                {
                    type: 'hidden',
                    name: 'forgot',
                    value: '1'
                },
                {
                    type: 'submit',
                    label: 'Ponastavite geslo'
                }
            ],
            schema_reset: [
                {
                    type: 'password',
                    name: 'password',
                    label: 'Novo geslo',
                    validation: 'required'
                },
                {
                    type: 'hidden',
                    name: 'reset',
                    value: '1'
                },
                {
                    type: 'submit',
                    label: 'Ponastavite geslo'
                }
            ],
            message: null,
            isLoggedIn: false,
            isRegistered: false,
            isForgotten: false,
            isForgottenSent: false,
            isResetPassword: false,
            isResetComplete: false,
            user: sessionStorage.getItem('user') !== '' ? sessionStorage.getItem('user') : '',
            loginError: false,
            loginErrorText: ''
        }
    },
    methods: {
        checkValue(e) {
            if (this.deposit !== null && this.deposit.toString().length >= 7) {
                e.preventDefault();
            }
        },
        nextStep() {
            if (this.deposit !== null) {
                sessionStorage.setItem('vzajemci-depozit', this.deposit);
                this.step = 2;
            }
        },
        userActions() {
            if (this.values.action === '1') {
                axios.post('/login', this.values)
                    .then(response => {
                        if (!response.error) {
                            console.log(response);
                            if (response.data.success) {
                                this.user = response.data.user;
                                sessionStorage.setItem('user', this.user);
                                VueScrollTo.scrollTo('#app');
                                this.isLoggedIn = true;
                                this.loginError = false;
                                this.loginErrorText = '';
                            } else {
                                this.loginErrorText = response.data.description;
                                this.loginError = true;
                            }
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            } else if (this.values.register === '1') {
                axios.post('/register', this.values)
                    .then(response => {
                        if (!response.error) {
                            console.log(response);
                            if (response.data.success) {
                                this.isRegistered = true;
                            } else {
                                console.log(response.data.description);
                                this.message = response.data.description;
                            }
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            } else if (this.values.forgot === '1') {
                axios.post('/forgotten-password', this.values)
                    .then(response => {
                        if (!response.error) {
                            this.isForgottenSent = true;
                            this.message = "";
                            console.log(response);
                            // if (response.data.success) {
                            //     this.isRegistered = true;
                            // } else {
                            // }
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            } else if (this.values.reset === '1') {
                axios.post('/set-password', this.values)
                    .then(response => {
                        if (!response.error) {
                            if (response.data.success) {
                                this.isResetComplete = true;
                                this.step = 2;
                                this.values.uuid = null;
                                this.isAfterReset = true;
                                this.active_form = this.schema_login;
                                this.message = 'Vaše geslo je bilo uspešno spremenjeno. <a href="/" class="login-button" title="Prijavite se">Prijavite se</a>';
                            }
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            }

        },
        userLogout() {
            this.isLoggedIn = false;
            sessionStorage.setItem('user', '');
        },
        userForgotPassword() {
            this.resetMessages();
            this.isForgotten = true;
            this.active_form = this.schema_forgot;
        },
        registerForm() {
            this.resetMessages();
            this.active_form = this.schema_register;
        },
        loginForm() {
            this.resetMessages();
            this.step = 2;
            this.active_form = this.schema_login;
        },
        resetMessages() {
            this.loginError = '';
            this.isLoggedIn = false;
            this.isRegistered = false;
            this.isForgotten = false;
            this.isForgottenSent = false;
            this.isResetPassword = false;
            this.isResetComplete = false;
            this.isAfterReset = false;
            this.message = '';
            this.values = {};
            if (this.$formulate !== undefined) {
                this.$formulate.reset('vzajemci-form');
            }
        }
    },
    created() {


    },
    mounted() {
        this.active_form = this.schema_register;
        if (this.uuid && !this.isAfterReset) {
            if (this.uuid === 'confirmed') {

            } else {
                console.log('uuid is set');
                sessionStorage.removeItem('user');
                this.isResetPassword = true;
                this.active_form = this.schema_reset;
                this.values.uuid = this.uuid;
            }
        }
        // check if loggedin
        let status = null;
        if (this.loggedin_user !== null ) {
            status = this.loggedin_user;
            sessionStorage.setItem('user', this.loggedin_user);
        } else {
            status = sessionStorage.getItem('user');
        }
        //let status = sessionStorage.getItem('user');
        if (status !== '' && status !== null) {
            this.user = status;
            this.isLoggedIn = true;
            this.isRegistered = false;
        }

        live('click', '.forgot-button', e => {
            e.preventDefault();
            this.userForgotPassword();
        });
        live('click', '.register-button', e => {
            e.preventDefault();
            this.registerForm();
        });
        live('click', '.login-button', e => {
            e.preventDefault();
            this.loginForm();
        });
    }
}
</script>
