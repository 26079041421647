<template>
    <section class="hero">

        <div class="container">

            <div class="row">

                <div class="col-12 pt-6 mb-sm-6 mb-md-0">
                    <h1><span class="upper">Izračunajte oportunitetno izgubo</span>Koliko vrednosti <br>izgublja vaš denar?</h1>
                </div>

            </div>

            <div class="row">

                <div class="col-lg-12 mt--8">

                    <p class="pl-0">
                        <picture>
                            <source media="(max-width: 480px)" srcset="/images/hero_img_sub_mobile.png">
                            <source media="(max-width: 1199.98px)" srcset="/images/hero_img_sub_mobile.png">
                            <img src="/images/hero_img.png" alt="#" class="img-fluid">
                        </picture>
                    </p>
                </div>

            </div>

            <div class="row">

                <div class="col-lg-5 hero-content" data-aos="fade-up" data-aos-duration="240" data-aos-easing="ease-in">
                    <p class="pl-0 mb-3 mb-lg-5 mb-md-4">Izračunajte, koliko kupne moči izpuhti iz vaših prihrankov na bančnem računu.</p>
                    <p class="pl-0"><button type="button" class="btn btn-link" v-scroll-to="'#calc'">Odkrijte sedaj</button>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/></svg>
                    </p>
                </div>

            </div>

        </div>

    </section>
</template>

<script>
export default {
    name: "Hero",
    methods: {
        scrollToContent() {

        }
    }
}
</script>

<style scoped>

</style>
