<template>
        <section class="calc" id="calc">

            <div class="container">

                <div class="row mb-8">

                    <div class="col-lg-5 my-auto">

                        <form class="calculator" data-aos="fade-up" data-aos-duration="240" data-aos-easing="ease-out">
                            <div class="form-group">
                                <label for="input1">Višina sredstev na računu</label>
                                <money v-model="deposit" v-bind="money" id="input1" @keypress.native="checkValue"></money>
<!--                                <input type="number" class="form-control" id="input1" aria-describedby="input1Help" min="1" max="999999999" placeholder="10.000 €" step="1" v-model="deposit" @keypress="checkValue">-->
                                <small id="input2" class="form-text text-muted">Izračun je informative narave.</small>
                            </div>
                        </form>

                    </div>

                    <div class="col-lg-2 my-auto">
                        <p class="text-left text-lg-center">
                            <picture>
                                <source media="(max-width: 991.98px)" srcset="/images/arrow-down.png">
                                <img src="/images/arrow-right.png" alt="#" class="img-fluid">
                            </picture>
                        </p>
                    </div>

                    <div class="col-lg-4 offset-lg-1 my-auto">
                        <p class="number text-left text-lg-right"><span class="upper-title">Realna vrednost prihrankov</span><br>{{ value_year|formatCurrency(0) }} €</p><small class="text-left text-lg-right">Povprečna letna inflacija v zadnjih 5 letih {{ inflation|formatCurrency(1) }}%.<br>Vir: TradingEconomics.com</small>
                        <hr class="calc">
                        <p class="number text-left text-lg-right red"><span class="upper-title">Oportunitetna izguba<sup>*</sup></span><br>{{ -value_year_loss|formatCurrency(0) }} €</p><small class="text-left text-lg-right">Si jo lahko privoščite?</small>
                    </div>

                </div>

                <hr>

                <div class="row mb-4">

                    <div class="col-lg-5">
                        <h3>Naložbeni odbor Vzajemci</h3>
                        <p>Stranke, ki so sodelovale z našimi svetovalci, so v zadnjem letu v povprečju ustvarile:</p>
                    </div>

                </div>

                <div class="row mb-8">

                    <div class="col-lg-5 my-auto">
                        <p class="text-left number"><span class="upper-title">višina vložka</span><br>{{ deposit|formatCurrency(0) }} €</p><small class="text-left text-lg-right">&nbsp;</small>
                    </div>

                    <div class="col-lg-2 my-auto">
                        <p class="text-left text-lg-center">
                            <picture>
                                <source media="(max-width: 991.98px)" srcset="/images/arrow-down.png">
                                <img src="/images/arrow-right.png" alt="#" class="img-fluid">
                            </picture>
                        </p>
                    </div>

                    <div class="col-lg-5 my-auto text-right">
                        <p class="text-left text-lg-right number"><span class="upper-title">Višina sredstev po preteku enega leta</span><br>{{ value_vzajemci|formatCurrency(0) }} €</p><small class="text-left text-lg-right">Povprečna donosnost Naložbenega odbora v zadnjem letu je znašala {{ profitability|formatCurrency(1) }}%<sup>*</sup>.</small>
                    </div>

                </div>

            </div>

        </section>

</template>

<script>
export default {
    name: "Calculator",
    data () {
        return {
            deposit: 0,
            inflation: 1,
            profitability: 5.4,
            value_year: 0.0,
            value_year_loss: 0.0,
            value_vzajemci: 0.0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' €',
                precision: 0,
                masked: false,
                max: 10000
            },
        }
    },
    watch: {
        deposit: function (newValue, oldValue) {
            this.calculate();
        }
    },
    methods: {
        checkValue(e) {
            if (this.deposit !== null && this.deposit.toString().length >= 7) {
                e.preventDefault();
          } else {

          }
        },
        calculate() {
            let deposit = this.deposit === null ? 0 : parseInt(this.deposit);
            if (Number.isInteger(deposit)) {
                this.value_year = deposit * (100 - this.inflation) / 100;
                this.value_vzajemci = deposit * (100 + this.profitability) / 100;
                this.value_year_loss = this.value_vzajemci - this.value_year;
                sessionStorage.setItem('depozit', this.deposit);
            } else {
                this.value_year = this.value_year_loss = this.value_vzajemci = 0;
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (sessionStorage.getItem('vzajemci-depozit') !== undefined) {
                this.deposit = parseInt(sessionStorage.getItem('vzajemci-depozit'));
            }
            sessionStorage.removeItem('vzajemci-depozit');
            this.calculate();
        });
    }
}
</script>
