<template>
    <div class="side-panel panel-mobile" @click="clickClose" :class="{'--is-visible': sidebarShown}">
        <div class="side-panel__container" :class="`--position-${position}`">
            <div class="side-panel__header">
                <div>
                    <button class="side-panel__close" @click.prevent="close">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5996 21.6663C24.1335 22.2002 24.1335 23.0658 23.5996 23.5996C23.0657 24.1335 22.2002 24.1335 21.6663 23.5996L12 13.9333L2.33368 23.5996C1.79982 24.1335 0.934264 24.1335 0.400407 23.5996C-0.13345 23.0658 -0.13345 22.2002 0.400407 21.6663L10.0667 12L0.400393 2.33366C-0.133464 1.7998 -0.133464 0.93425 0.400393 0.400393C0.93425 -0.133465 1.7998 -0.133464 2.33366 0.400393L12 10.0667L21.6664 0.400393C22.2002 -0.133464 23.0658 -0.133465 23.5996 0.400393C24.1335 0.93425 24.1335 1.7998 23.5996 2.33366L13.9333 12L23.5996 21.6663Z" fill="#54BAB3"/>
                        </svg>
                    </button>
                </div>
                <div class="side-panel__title" v-if="!submitted">
                    <hr>
                    <h4>Želim svetovanje</h4>
                    <p>Skupina Vzajemci velja za vodilno finančno svetovalno podjetje v regiji. V skoraj 20 letih delovanja nam je zaupalo več kot 20.000 vlagateljev. Naše svetovalce odlikuje neodvisen pristop, odlično znanje ter želja po dolgoročnem sodelovanju, kar je recept za vaše zadovoljstvo.</p>
                </div>
                <div class="side-panel__title" v-else>
                    <hr>
                    <h4>Hvala za izkazano zaupanje!</h4>
                    <p>Kmalu vas bo kontaktiral strokovnjak Vzajemcev z nadaljnjimi navodili.</p>
                </div>
            </div>

            <div class="side-panel__body">
                <div v-if="!submitted">
                    <FormulateForm
                        v-model="values"
                        :schema="schema_form"
                        @submit="saveForm"
                    />
                    <p v-if="error" v-text="error"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueFormulate from '@braid/vue-formulate';
let sl = require('./lang/sl.js').default;

Vue.use(VueFormulate, {
    plugins: [ sl ],
    locale: 'sl'}
);

export default {
    name: "Sidebar",
    data () {
        return {
            position: 'right',
            sidebarShown: false,
            submitted: false,
            values: {},
            error: null,
            schema_form: [
                {
                    type: 'text',
                    name: 'name',
                    label: 'Vaše ime in priimek',
                    validation: 'required'
                },
                {
                    type: 'email',
                    name: 'email',
                    label: 'Vaš e-naslov',
                    validation: '^required|email'
                },
                {
                    type: 'text',
                    name: 'phone',
                    label: 'Telefonska številka'
                },
                {
                    type: 'textarea',
                    name: 'message',
                    label: 'Vaše želje',
                    rows: '5',
                    placeholder: 'Npr. želim ustvariti naložbeni portfelj'
                },
                {
                    type: 'checkbox',
                    name: 'terms',
                    label: 'Strinjam se s pogoji poslovanja.'
                },
                {
                    type: 'submit',
                    label: 'Pošljite povpraševanje'
                }
            ],
        }
    },
    methods: {
        show() {
            this.sidebarShown = true;
            document.querySelector('html').classList.add('overlay');
        },
        close() {
            this.sidebarShown = false;
            this.submitted = false;
            document.querySelector('html').classList.remove('overlay');
        },
        clickClose (e) {
            e.stopPropagation();
            if (e.target.classList.contains('side-panel')) {
                this.close();
            }
        },
        async saveForm() {
            this.values.deposit = sessionStorage.getItem('depozit') !== undefined ? sessionStorage.getItem('depozit') + ' €': 'ni podatka';
            axios.post('/consulting', this.values)
                .then(response => {
                    if (response.data.success) {
                        this.submitted = true;
                    } else {
                        this.error = response.data.description;
                        this.submitted = false;
                    }
                })
                .catch(function (error) {
            });
        },
    },
    created() {
        let self = this;
        window.addEventListener('keydown', function(event) {
            if (self.sidebarShown && event.key === 'Escape') {
                self.close();
            }
        });
        live('click', '.btn-consult', e => {
            this.show();
        });
    },
    mounted() {

    }
}
</script>
