<template>
    <div class="container">
        <nav class="navbar navbar-light bg-light">
            <a class="navbar-brand" href="/" title="#">
                <img src="/images/bull_logo.svg" width="56" height="72" alt="Logotip Vzajemci" loading="lazy">
            </a>
            <button type="button" class="btn btn-primary btn-lg btn-consult"><span>Naročite svetovalca</span></button>
        </nav>
    </div>
</template>

<script>
export default {
    name: "Header.vue"
}
</script>

