<template>
    <footer>
        <div class="container">
            <hr>
            <div class="row">

                <div class="col-12 col-lg-4">
                    <p>
                        <a href="https://www.vzajemci.com/" title="VZAJEMCI SKUPINA d.o.o." target="_blank"><img src="/images/skupina_logo.svg" width="260" height="72" alt="Logotip Vzajemci Skupina" loading="lazy"></a>
                    </p>
                    <small>VZAJEMCI SKUPINA d.o.o.<br>Litostrojska 52<br>1000 Ljubljana</small>
                </div>

                <div class="col-12 col-xl-4 offset-xl-4 col-lg-6 offset-lg-2 col-md-12 offset-md-0 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0 text-xl-right text-lg-right text-md-left text-sm-left">
                    <h5 class="mb-5 pt-lg-0 mb-xl-5 pt-md-5 pt-sm-4">Če potrebujete nasvet naših strokovnjakov, nam pišite na<br><a href="mailto:nasvet@vzajemci.com" title="Pišite nam na nasvet@vzajemci.com">nasvet@vzajemci.com</a></h5>
                    <p>
                        <a href="https://twitter.com/skupinavzajemci" class="social-icon" target="_blank" title="Twitter Vzajemci Skupina d.o.o." rel="noopener nofollow"><i class="fab fa-twitter"></i></a>
                        <a href="https://www.linkedin.com/company/vzajemci-skupina-d.o.o./" class="social-icon" target="_blank" title="LinkedIn Vzajemci Skupina d.o.o." rel="noopener nofollow"><i class="fab fa-linkedin-in"></i></a>
                        <a href="https://www.facebook.com/Vzajemci" class="social-icon" target="_blank" title="Facebook Vzajemci Skupina d.o.o." rel="noopener nofollow"><i class="fab fa-facebook"></i></a>
                    </p>
                </div>

            </div>
            <hr>
            <div class="row mt-3 mt-xl-5">
                <div class="col-12 order-2 col-xl-4 order-xl-1 col-lg-5 order-lg-2">
                    <small>&copy; 2021 <a href="https://www.vzajemci.com" target="_blank" title="Vzajemci Skupina d.o.o.">VZAJEMCI SKUPINA d.o.o.</a> Vse pravice pridržane.</small>
                </div>

                <div class="col-12 order-1 col-xl-6 order-xl-2 col-lg-12 order-lg-1 offset-xl-2 text-xl-right text-lg-left text-md-left text-sm-left">
                    <small class="footer-links"><a href="https://www.vzajemci.com/zasebnost" target="_blank" title="Politika zasebnosti">Politika zasebnosti</a> <a href="https://www.vzajemci.com/pravna-obvestila" target="_blank" title="Pravno obvestilo">Pravno obvestilo</a> <a href="https://www.vzajemci.com/piskotki" target="_blank" title="Pravno obvestilo">Politika piškotkov</a> <a href="#" class="trigger-cookies-change" title="Nastavitve piškotkov">Nastavitve piškotkov</a></small>
                </div>
            </div>

        </div>

    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>
