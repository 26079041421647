<template>
    <section class="call-me">

        <div class="container-fluid">

            <div class="row">

                <div class="col-lg-5">
                    <img src="/images/stol-callme.png" class="img-fluid atmo-img" alt="#">
                </div>

                <div class="col-lg-7 form-back">

                    <div v-if="!submitted"  data-aos="fade-up" data-aos-duration="400" data-aos-easing="linear">
                        <h4 class="mb-5">Želim svetovanje</h4>
                        <p>Skupina Vzajemci velja za vodilno finančno svetovalno podjetje v regiji. V skoraj 20 letih delovanja nam je zaupalo več kot 20.000 vlagateljev.</p>
                        <FormulateForm v-model="values" :schema="schema_form" @submit="saveForm">
                        </FormulateForm>
                    </div>
                    <div v-else>
                        <h4 class="mb-5">Hvala za izkazano zaupanje!</h4>
                        <p>Kmalu vas bo kontaktiral strokovnjak Vzajemcev z nadaljnjimi navodili.</p>
                    </div>

                </div>

            </div>

        </div>

    </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Slovenian } from 'flatpickr/dist/l10n/sl.js'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
    name: "Contact",
    props: {
      user: String
    },
    data () {
        return {
            submitted: false,
            date: null,
            config: {
                dateFormat: 'd.m. Y H:i',
                enableTime: true,
                minuteIncrement: 10,
                time_24hr: true,
                locale: Slovenian,
                minTime: '0:00',
                maxTime: '23:55',
            },
            values: {
                date: new Date(),
            },
            schema_form: [
                {
                    type: 'text',
                    name: 'phone',
                    label: 'Telefonska številka'
                },
                {
                    type: 'submit',
                    label: 'Pošljite povpraševanje'
                }
            ]
        }
    },
    components: {
        flatPickr,
        VueCtkDateTimePicker
    },
    methods: {
        async saveForm() {
            //this.values.date = this.date;
            this.values.user = this.user;
            this.values.deposit = sessionStorage.getItem('depozit') !== undefined ? sessionStorage.getItem('depozit') + ' €': 'ni podatka';
            axios.post('/contact', this.values)
                .then(response => {
                    if (response.data.success) {
                        this.submitted = true;
                    }
                })
                .catch(function (error) {
                });
        }
    },
    created() {
        this.values.user = this.user;
    }
}
</script>
