import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

import fontawesome from '@fortawesome/fontawesome-free/js/all.js';

// Live event binding
global.live = function live (eventType, elementQuerySelector, cb) {
    document.addEventListener(eventType, function (event) {
        var qs = document.querySelectorAll(elementQuerySelector);
        if (qs) {
            var el = event.target, index = -1;
            while (el && ((index = Array.prototype.indexOf.call(qs, el)) === -1)) {
                el = el.parentElement;
            }
            if (index > -1) {
                cb.call(el, event);
            }
        }
    });
}

window.Vue = require('vue');
window.Vue.config.productionTip = false;

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Vue.component('vz-login', require('./components/Login.vue').default);
Vue.component('vz-sidebar', require('./components/Sidebar.vue').default);
Vue.component('vz-calculator', require('./components/Calculator').default);
Vue.component('vz-contact', require('./components/Contact').default);
Vue.component('vz-page', require('./components/Layout/Page').default);
Vue.component('vz-hero', require('./components/Layout/Hero').default);
Vue.component('vz-header', require('./components/Layout/Header').default);
Vue.component('vz-footer', require('./components/Layout/Footer').default);

Vue.filter('formatCurrency', function(value, decimals) {
    if (value) {

        if (value == 0) {
            console.log(value, decimals);
            return 0;
        }
        let val = (value/1).toFixed(decimals).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
});

const app = new Vue({
    el: '#app'
});

import * as klaro from 'klaro';
import 'klaro/dist/klaro.css';
live('click', '.trigger-cookies-change', e => {
    e.preventDefault();
    klaro.show();
});
